import React from 'react';
import './MainLayout.css'
import Header from "../../components/Header/Header";
import FooterContainer from "../../components/Footer/FooterContainer/FooterContainer";
import { HeaderProvider } from '../../context/Header/HeaderContext';
import WhatsAppFAB from '../../components/WhatsAppFAB/WhatsAppFAB';
import backgroundImage from '../../assets/fondo-web-forvet.svg'
import PulseFABButton from "../../components/PulseFABButton/PulseFABButton";

function MainLayout({
   children,
   overflow = 'visible',
   bg = null,
}) {

   return (
      <div className="mainLayout__container"
         style={{
            overflow,
            background: bg ?? '',
            backgroundImage: bg
               ? ''
               : `url(${backgroundImage})`
         }}
      >
         <HeaderProvider>
            <Header />
         </HeaderProvider>
         <main>
            {children}
            <WhatsAppFAB />
            <PulseFABButton />
         </main>
         <FooterContainer />
      </div>
   );
}

export default MainLayout;