import React from "react";
import "./LandingHero.css";
import { Typewriter } from "react-simple-typewriter";
import { useAppContext } from "../../../context/AppState/AppContext";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import GlassCard from "../../../components/GlassCard/GlassCard";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import HeroCarouselSkeleton from "./HeroCarouselSkeleton";
import SearchBanner from "../../../components/SearchBanner/SearchBanner";
import { Link } from "react-router-dom";
import { Button, Stack } from "@mantine/core";
import { IconCrown } from "@tabler/icons-react";
import EliteButton from "../../../components/EliteButton/EliteButton";

function LandingHero() {

   const { specialtiesInfo } = useAppContext()

   return (
      <div className="landingHero__container">
         <div className="landingHero__column">

            {/*<Stack p={0} gap={0} align="center">*/}
            {/*<IconCrown/>*/}
            {/*   <p className="landingHero__offer-text">¿Estás buscando algo exclusivo?</p>*/}
            {/*   <Button variant="transparent" component={Link} to='/oferta-elite'>*/}
            {/*      <EliteButton />*/}
            {/*   </Button>*/}
            {/*</Stack>*/}

            <h1 className="landingHero__title">
               Donde expertos <br /> forman y <br />
               <span className="landingHero__gradient">
                  <Typewriter
                     words={["comparten", "construyen", "colaboran", "transforman"]}
                     delaySpeed={1500}
                     cursor={true}
                     cursorStyle="|"
                     cursorBlinking={true}
                     loop={false}
                  />
               </span>
            </h1>

            <div className="landingHero__block">
               <p className="landingHero__text">
                  Potenciá tus conocimientos con la mentoría de expertos y marcá la diferencia en la comunidad.
               </p>
            </div>
            <SearchBanner />





         </div>
         <div className="landingHero__column ">
            {specialtiesInfo.isLoading ? <HeroCarouselSkeleton />
               : <Splide
                  options={{
                     type: 'loop',
                     drag: 'free',
                     focus: 'center',
                     autoWidth: true,
                     gap: 40,
                     arrows: false,
                     pagination: false,
                     autoScroll: {
                        speed: 1,
                     },
                  }}
                  extensions={{ AutoScroll }}
               >
                  {(!specialtiesInfo.error && !specialtiesInfo.isLoading && specialtiesInfo.data) && specialtiesInfo.data?.map(item => (
                     <SplideSlide key={item._id}>
                        <GlassCard item={item} />
                     </SplideSlide>
                  ))}
               </Splide>
            }

         </div>

      </div>
   );
}

export default LandingHero;