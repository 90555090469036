import { Flex } from "@mantine/core";
import ImageOverShape from "../../../components/ImageOverShape/ImageOverShape";
import "./CoursesHero.css";

const HERO_DATA =
{
   title: "Encontrá el Camino hacia el Éxito Profesional con Nuestros Cursos",
   description: "Diseñados para impulsar tu excelencia académica, nuestros programas te brindarán las herramientas necesarias para destacarte en la profesión. Encuentra, con nosotros, tu camino hacia la especialización.",
   imageUrl: "https://i.imgur.com/nDLxd7p.png",
}


const textTitle = HERO_DATA.title?.split(' ');

const CoursesHero = () => {
   return (
      <div className="coursesLandingHero-father-div-container">
         <div className="coursesLandingHero-content-wrapper">
            <div className="coursesLandingHero-image-container">
               <ImageOverShape
                  straightSide="left"
                  height={150}
                  imageUrl={HERO_DATA.imageUrl}
               />
            </div>
            <div className="coursesLandingHero-all-content-container-div">
               <div className="coursesLandingHero-title-and-description-div">
                  <div className="coursesLandingHero-title-and-description-content-div">
                     <h2 className="coursesLandingHero-title-styling">
                        <Flex justify={{ base: 'center', sm: 'start' }} gap={8} w='100%' style={{ flexWrap: 'wrap' }}>
                           {textTitle?.map(word => (
                              <span className="coursesLandingHero-title-styling--outline" key={word}>{word}</span>
                           ))}
                        </Flex>
                     </h2>
                     <p className="coursesLandingHero-paragraph-p-styling">
                        {HERO_DATA.description}
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CoursesHero;
