import { IconAsterisk, IconAward, IconBulb, IconChevronRight, IconCrown, IconDatabase, IconMoodCrazyHappy, IconRocket, IconStar, IconTrophy, IconVip } from '@tabler/icons-react';
import './EliteIntro.css'
import { useScroll, motion, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { Button, Flex, Stack } from '@mantine/core';

export const EliteIntro = () => {


   const ref = useRef(null);
   const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["start start", "end start"],
   });

   return (
      <>
         <div ref={ref} style={{ position: "relative" }}>
            {CARDS.map((c, idx) => (
               <Card
                  key={c.id}
                  card={c}
                  scrollYProgress={scrollYProgress}
                  position={idx + 1}
               />
            ))}
         </div>
      </>
   );
};

const Card = ({ position, card, scrollYProgress }) => {
   const scaleFromPct = (position - 1) / CARDS.length;
   const y = useTransform(scrollYProgress, [scaleFromPct, 1], [0, -CARD_HEIGHT]);

   const isOddCard = position % 2;

   return (
      <motion.div
         style={{
            height: CARD_HEIGHT,
            y: position === CARDS.length ? undefined : y,
            background: isOddCard ? "#1d1d1d" : "#dadada",
            color: isOddCard ? "#dadada" : "#1d1d1d",
         }}
         className="EliteIntro-Card-container"
      >
         <card.Icon className='EliteIntro-Card--icon' size={70} />
         <h3 className="EliteIntro-Card--title ">
            {card.title}
         </h3>
         {/* <p className="EliteIntro-Card--description"> */}
         {card.description}
         {/* </p> */}
         {card.routeTo &&
            <Button size='lg' color='#BF953F' mt={{base: '1rem', sm: '2rem'}} component='a' href={card.routeTo} >{card.btnText}</Button>
         }
      </motion.div>
   );
};

const CARD_HEIGHT = 600;

const CARDS = [
    {
        id: 1,
        Icon: IconRocket,
        title: "Especialidades para especialistas",
        description:
            <p className='EliteIntro-Card--description'>
                Diseñadas para aquellos que buscan llevar su conocimiento a un nivel superior, pensado para especialistas que desean ser referentes en su campo.
            </p>
    },
    {
        id: 2,
        Icon: IconCrown,
        title: "Jerarquizando la profesión",
        description:
            <p className='EliteIntro-Card--description'>
                Programas creados por expertos de renombre, orientados a profesionales que valoran la exclusividad, buscan consolidar su liderazgo y están dispuestos a sobresalir.
            </p>,
    },
    {
        id: 3,
        Icon: IconTrophy,
        title: "Innovación académica",
        description:
            <p className='EliteIntro-Card--description'>
                Cada capacitación está estructurada con una visión innovadora y un enfoque individualizado, brindando la excelencia necesaria para marcar la diferencia.
            </p>,
    },
    {
        id: 4,
        Icon: IconStar,
        title: "¿Por qué elegir Élite?",
        description:
            <Stack align='start'>
                <Flex align='center' gap={4}>
                    <IconChevronRight />
                    <p className='EliteIntro-Card--description'>Expertos de prestigio</p>
                </Flex>
                <Flex align='center' gap={4}>
                    <IconChevronRight />
                    <p className='EliteIntro-Card--description'>Grupos de aprendizaje reducidos</p>
                </Flex>
                <Flex align='center' gap={4}>
                    <IconChevronRight />
                    <p className='EliteIntro-Card--description'>Mentoría personalizada</p>
                </Flex>
                <Flex align='center' gap={4}>
                    <IconChevronRight />
                    <p className='EliteIntro-Card--description'>Networking de alto nivel</p>
                </Flex>
            </Stack>,
    },
];