import React from 'react';

function LazyVideo({poster, src}) {
    return (
        <video
            controls
            autoPlay
            muted
            width="100%"
            style={{border: "none", borderRadius: "8px"}}
            poster={poster ?? null}
        >
            <source
                src={src}
                type="video/mp4"
            />
            Your browser does not support the video tag.
        </video>
    );
}

export default LazyVideo;