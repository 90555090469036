import { Flex, Select } from "@mantine/core";
import "./CoursesFilter.css";
import { useAppContext } from "../../../context/AppState/AppContext";
import { useEffect, useState } from "react";
import SearchBanner from "../../../components/SearchBanner/SearchBanner";
import { useSearchParams } from "react-router-dom";


const SPECIALTIES = [
   'Medicina Felina',
   'Dermatología',
   'Diagnóstico por Imágenes-Ecografía',
   'Diagnóstico por Imágenes-Radiología',
   'Terapias Fisicas y Rehabilitacion',
   'Cirugía de tejidos blandos',
   'Traumatología',
   'Cardiologia',
   'Reproducción y neonatología',
   'Laboratorio y análisis clínicos',
   'Toxicología',
   'Medicina Interna',
   'Anestesiología',
   'Urología',
   'Citología',
   'Gastroenterología'
]

const ACADEMIC_UNITS = [
   'POSGRADO',
   'CURSO',
   'DIPLOMADO',
   'MASTERCLASS',
   'WEBINAR',
   'TALLER'
]

const MONTHS = [
   'ENERO',
   'FEBRERO',
   'MARZO',
   'ABRIL',
   'MAYO',
   'JUNIO',
   'JULIO',
   'AGOSTO',
   'SEPTIEMBRE',
   'OCTUBRE',
   'NOVIEMBRE',
   'DICIEMBRE'
]

const CoursesFilter = ({setSpecialtyFilter, setAcademicUnitFilter, setMonthFilter}) => {
   const { specialtiesInfo } = useAppContext();
   const [specialtiesOptions, setSpecialtiesOptions] = useState(SPECIALTIES);
   const [searchParams, setSearchParams] = useSearchParams();

   // Leer filtros desde la URL
   const specialtyFilter = searchParams.get("especialidad") || "";
   const academicUnitFilter = searchParams.get("tipo") || "";
   const monthFilter = searchParams.get("mes") || "";


   useEffect(() => {
      if (specialtiesInfo?.data) {
         setSpecialtiesOptions(specialtiesInfo?.data.map(specialty => specialty.name.toUpperCase()));
      }
   }, [specialtiesInfo]);

   // Función para actualizar la URL cuando cambian los filtros
   const handleFilterChange = (filterKey, value, setter) => {
      if (value) {
         searchParams.set(filterKey, value);
      } else {
         searchParams.delete(filterKey);
         setter(null)
      }
      setSearchParams(searchParams);
   };

   return (
      <div className="coursesFilter-father-div">
         <div className="coursesFilter-filter-div-container">
            <Flex align='center' mb={32} direction={{ base: 'column', sm: 'row' }}>
               <span className="coursesFilter-span-styling">Especialidad</span>
               <Select
                  size="md"
                  value={specialtyFilter}
                  onChange={(value) => handleFilterChange("especialidad", value, setSpecialtyFilter)}
                  placeholder={specialtiesOptions[0]}
                  clearable
                  searchable
                  nothingFoundMessage="Sin resultados para tu búsqueda"
                  data={specialtiesOptions}
                  styles={{ option: { borderRadius: 0 }, input: {backgroundColor: 'transparent', color: 'var(--color-text-primary)'} }}
                  classNames={{ root: "courserFilter-select-wrapper-customization", option: "courserFilter-select-option-customization" }}
               />
               <span className="coursesFilter-second-span-styling">
                  Tipo de Curso
               </span>
               <Select
                  size="md"
                  value={academicUnitFilter}
                  onChange={(value) => handleFilterChange("tipo", value, setAcademicUnitFilter)}
                  placeholder={ACADEMIC_UNITS[0]}
                  clearable
                  searchable
                  nothingFoundMessage="Sin resultados para tu búsqueda"
                  data={ACADEMIC_UNITS}
                  styles={{ option: { borderRadius: 0 }, input: {backgroundColor:"transparent", color: 'var(--color-text-primary)'} }}
                  classNames={{ root: "courserFilter-select-wrapper-customization", option: "courserFilter-select-option-customization" }}
               />
               <span className="coursesFilter-second-span-styling">
                  Mes de inicio
               </span>
               <Select
                  size="md"
                  value={monthFilter}
                  onChange={(value) => handleFilterChange("mes", value, setMonthFilter)}
                  placeholder={MONTHS[0]}
                  clearable
                  searchable
                  nothingFoundMessage="Sin resultados para tu búsqueda"
                  data={MONTHS}
                  styles={{ option: { borderRadius: 0 }, input: {backgroundColor:"transparent", color: 'var(--color-text-primary)'} }}
                  classNames={{ root: "courserFilter-select-wrapper-customization", option: "courserFilter-select-option-customization", dropdown: "courserFilter-dropdown-option-customization" }}
               />
            </Flex>
            <SearchBanner maxWidth='100%' btnText={null} />
         </div>
      </div>
   );
};

export default CoursesFilter;
