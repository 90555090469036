import './AccountInfoItem.css'
import { useState } from 'react';
import { IconCopy } from '@tabler/icons-react';
export default function AccountInfoItem({ headline, text, canCopy = false }) {
   const [copied, setCopied] = useState(false)

   const handleCopy = () => {
      navigator.clipboard.writeText(text)
         .then(() => {
            setCopied(true)
            setTimeout(() => {
               setCopied(false)
            }, 1000)
         })
         .catch((err) => {
            console.log(err)
         })
   }

   return (
      <div className='account-bank-info-item-container'>
         <h5 className='account-bank-info-item-headline'>{headline}</h5>
         <div className={`account-bank-info-item-textGroup ${!canCopy ? 'textGroup--paddingRight' : ''} `}>
            <h6 className={`account-bank-info-item-text ${canCopy ? 'account-bank-info-item-text--bold' : ''}`}>{text}</h6>
            {canCopy && < IconCopy onClick={handleCopy} className={`account-bank-info-item-copyIcon `} color={ copied ? 'var(--color-tertiary)' : 'var(--color-secondary)' }/>}
         </div>
      </div>
   )
}
