import { useScroll } from 'framer-motion';
import { useFetch } from '../../hooks/useFetch'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import './EliteOffer.css'
import { useEffect, useRef } from 'react'
import EliteCard from '../../components/EliteCard/EliteCard';
import EliteCarSkeleton from '../../components/EliteCard/EliteCarSkeleton';
import { EliteIntro } from '../../features/EliteOffer/EliteIntro/EliteIntro';

export default function EliteOffer() {

   const URL = process.env.REACT_APP_BACKEND_SERVER + `/info/eliteAcademicUnits`;
   const {
      data,
      error,
      isLoading
   } = useFetch(URL)

   useEffect(() => {
      window.document.body.style.backgroundColor = '#131313';

      return () => {
         window.document.body.style.backgroundColor = 'var(--mantine-color-body)';
      };
   }, []);

   const ref = useRef(null);
   const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["start start", "end start"],
   });


   useEffect(() => {
      if (!isLoading && data) {
         window.scrollBy({ top: 1, behavior: 'smooth' });
      }
   }, [isLoading, data]);


   return (
      <MainLayout bg='#1d1d1d' isElite>
         <div className="EliteOffer-disappearing-features-container">
            <EliteIntro />

            <div className="EliteOffer-features-grid">
               <div className="EliteOffer-copy-container" id='ofertas-elite'>
                  <h2 className="EliteOffer-copy-title">
                     Capacitaciones Elite
                  </h2>
                  <p className="EliteOffer-copy-text">
                     Descubre lo que hemos estado preparando para vos. Capacitaciones de alto nivel, con los mejores expertos en el área.
                  </p>
               </div>
               <div className="EliteOffer-carousel-container">
                  <div className="EliteOffer-carousel-gradient" />

                  {isLoading && <EliteCarSkeleton />}
                  <div ref={ref} className="EliteOffer-carousel-content">

                     {(!isLoading && !error && data) &&
                        data.map((item, idx) => (
                           <EliteCard scrollYProgress={scrollYProgress} position={idx + 1} numItems={data.length} item={item} />
                        ))
                     }
                  </div>
                  <div className="EliteOffer-carousel-buffer" />
               </div>
            </div>
         </div>

      </MainLayout>
   )
}
