import IconFilled from "../../../components/IconFilled/IconFilled";

export default function StepsItem({icon, backgroundColor = 'var(--color-secondary)', rounded='var(--border-radius-lg)', text}) {
   return (
      <div className='transfer-bank-steps-item'>
         <IconFilled backgroundColor={backgroundColor} rounded={rounded}>
            {icon}
         </IconFilled>
         <p className='transfer-bank-steps-text'>{text}</p>
      </div>
   )
}
