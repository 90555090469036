import './Header.css'
import { Burger, Button, Flex } from '@mantine/core';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import { useHeaderContext } from '../../context/Header/HeaderContext';
import HeaderNavList from './HeaderNavList/HeaderNavList';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useEliteStatus from '../../hooks/useEliteStatus';

export default function Header() {
   const { isMegaMenu, isScrolling, toggleBurger, setToggleBurger } = useHeaderContext()
   const { width } = useWindowDimensions()
   const isMobile = width <= 768

   const isElite = useEliteStatus()

   return (
      <header className={isMegaMenu || isScrolling || toggleBurger ? 'navbar active' : 'navbar'}>

         <nav className='nav-container'>


            < HeaderLogo />

            < HeaderNavList  />


            <Flex w={'100%'} justify={'end'}>
               <Flex justify={'end'} align='center' gap={10} w='100%' style={{ flexBasis: 1 }}>
                  <Button
                     component={Link}
                     to='/campus'
                     fw='normal'
                     color={isElite ? '#BF953F' : 'var(--color-tertiary)'}
                     style={{ display: isMobile ? 'none' : '' }}
                  >
                     CAMPUS
                  </Button>
               </Flex>
            </Flex>

            <Burger
               color='var(--color-text-primary)'
               className='burger-btn'
               opened={toggleBurger}
               onClick={() => {
                  setToggleBurger(!toggleBurger)
               }}
               aria-label="Toggle navigation"
            />
         </nav>

      </header>
   )
}