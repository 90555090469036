import ImageOverShape from '../../../components/ImageOverShape/ImageOverShape'
import './SpecialtiesHero.css'
import vetImg from '../../../assets/specialties/veterinario.png'

export default function SpecialtiesHero({title, description, imageUrl = vetImg, left = 'unset', right = 'unset', height = 200, square = null,backgroundColor = 'transparent', imageBackgroundColor = 'var(--color-secondary)', firstImage = false }) {

   return (
      <div className='specialties-hero-container' style={{background: backgroundColor}}>
         <div className='specialties-hero-heading' style={firstImage ? {order: 2} : {}}>
            <h2>{title}</h2>
            <p>{description}</p>
         </div>
         <div className='specialties-hero-imageOver-container'>
            < ImageOverShape backgroundColor={imageBackgroundColor} square={square} imageUrl={imageUrl} height={height} left={left} right={right} />
         </div>
      </div>
   )
}
