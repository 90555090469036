import {Badge, Button, Card, Group} from "@mantine/core";
import {
    IconDeviceImac,
    IconCalendarDue,
    IconCreditCard,
} from "@tabler/icons-react";
import "./CardInfoColumn.css";
import {formattedPrice} from "../../../utils/formatted-price";
import {useAppContext} from "../../../context/AppState/AppContext";
import {currencyFormat} from "../../../utils/currencyFormat";

export default function CardInfoColumn({
                                           classes = 8,
                                           price = 1000,
                                           date = "15 sep",
                                           imgUrl,
                                           imgAlt,
                                           title,
                                           tag,
                                           type = "curso",
                                           buttonText = "mas",
                                           buttonColor = "dark",
                                           buttonVariant = "filled",
                                           booleanValue = false,
                                           buttonAction,
                                       }) {
    const {isUSD} = useAppContext()
    return (
        <Card classNames={{root: "cardInfo-column"}} shadow="sm" padding="15px" >
            <Card.Section className="cardInfo-image-container">
                <img
                    className="cardInfo-image"
                    src={imgUrl}
                    height={200}
                    alt={imgAlt}
                />
            </Card.Section>
            <div className="cardInfo-description-container">
                <Group justify="space-between" mt="md" mb="xs">
                    <Group w={"100%"}>
                        <Badge classNames={{root: "cardInfo-description-badge-tag"}} variant="light">
                            {tag}
                        </Badge>
                        <Badge classNames={{root: "cardInfo-description-badge-type"}}>
                            {type}
                        </Badge>
                    </Group>
                    <Group>
                        <h3 className="card-info-title">{title}</h3>
                    </Group>
                </Group>

                {booleanValue && (
                    <Group justify="flex-start" align="center" grow={1}
                           className="cardInfo-commercialInformation-container">
                        <div className="cardInfo-commercialInformation--row">
                            <div className="card-items-info--group">
                                <IconDeviceImac className="card-items-icon" size={24}/>
                                <span className="text-overflow-ellipsis">{classes} clases</span>
                            </div>

                            <div className="card-items-info--group">
                                <IconCalendarDue className="card-items-icon" size={24}/>
                                <span className="text-overflow-ellipsis">{date.toUpperCase()}</span>
                            </div>
                        </div>
                        <div className="card-items-info--group">
                            <IconCreditCard className="card-items-icon" size={24}/>
                            <span
                                className="text-overflow-ellipsis">{formattedPrice(currencyFormat(price), isUSD)}</span>
                        </div>
                    </Group>
                )}

                <Button
                    onClick={buttonAction}
                    variant={buttonVariant}
                    color={buttonColor}
                    fullWidth
                    mt="md"
                >
                    {buttonText.toUpperCase()}
                </Button>
            </div>
        </Card>
    );
}
