import { Flex } from "@mantine/core";
import ImageOverShape from "../../../components/ImageOverShape/ImageOverShape";
import { useAppContext } from "../../../context/AppState/AppContext";
import "./CourseHero.css";

const CourseHero = (props) => {
   const { width, isMobile } = useAppContext()

   const { title, description, imageUrl, isOpen = true } = props;

   const textTitle = title?.split(' ');
   const length = Math.floor(title?.split(' ')?.length / 2);

   const titleUp = textTitle?.slice(0, length);
   const titleDown = textTitle?.slice(length);

   return (
      <div className="courseHero-div-father-container">
         <div className="courseHero-div-father-wrapper">
            <div className="courseHero-all-content-container-div">
               <div className="courseHero-title-and-description-div">
                  <h2 className="courseHero-title-h2-styling">
                     <Flex justify={{base: 'center', sm: 'start'}} gap={8} w='100%' style={{flexWrap: 'wrap'}}>
                        {titleUp?.map(word => (
                           <span className="courseHero-title-h2-styling--outline" key={word}>{word}</span>
                        ))}
                     </Flex>
                     <Flex justify={{base: 'center', sm: 'start'}} gap={8} w='100%' style={{flexWrap: 'wrap'}}>
                        {titleDown?.map(word => (
                           <span key={word}>{word}</span>
                        ))}
                     </Flex>
                  </h2>
                  <p className="courseHero-paragraph-p-styling">
                     {description}
                  </p>
                  {!isOpen && !isMobile && <span className="sectionTitle-upText">¡Inscripciones cerradas!</span>}
               </div>
               <div className="courseHero-image-container">
                  <ImageOverShape right={width > 1200 ? 'unset' : '0'} imageUrl={imageUrl} height={180} />
               </div>
            </div>
         </div>
      </div>
   );
};

export default CourseHero;
