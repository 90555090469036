import './SectionTitle.css'
export default function SectionTitle({ upText = '', downText = '', children }) {
   return (
      <div className="sectionTitle-container">
         {upText && <h3 className="sectionTitle-upText">{upText.toUpperCase()}</h3>}
         {downText && <h2 className="sectionTitle-downText">{downText.toUpperCase()}</h2>}
         {children}
      </div>
   )
}
