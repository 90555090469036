import './IconFilled.css'
export default function IconFilled({ children, width = 'max-content', height = 'max-content', backgroundColor = 'transparent', rounded = '0px', padding = '5px', ...others }) {
   return (
      <div 
         style={{ 
            backgroundColor, 
            width, 
            height, 
            borderRadius: rounded, 
            padding,
         }} className='icon-filled-container'
            {...others}
         >
            {children}
      </div>
   )
}
