import React, {useEffect, useState} from "react";
import "./Catalog.css";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
import {useNavigate} from "react-router-dom";
import {Button, Stack, TextInput} from "@mantine/core";
import {IconDownload} from "@tabler/icons-react";
import GlassCard from "../../components/GlassCard/GlassCard";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import axios from "axios";
import moment from "moment/moment";
import {errorHandler} from "../../utils/errorHandler";
import showNotification from "../../utils/showNotification";

const MONTHS_DICT = {
    'enero': 0,
    'febrero': 1,
    'marzo': 2,
    'abril': 3,
    'mayo': 4,
    'junio': 5,
    'julio': 6,
    'agosto': 7,
    'septiembre': 8,
    'octubre': 9,
    'noviembre': 10,
    'diciembre': 11
};

function Catalog() {
    const [email, setEmail] = useState("");
    const [coursesData, setCoursesData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, isLoading] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleDownload = async () => {
        if (!validateEmail(email)) {
            setError("Por favor, ingresa un correo electrónico válido.");
            showNotification({
                color: 'red',
                status: 'error',
                title: 'Operación fallida.',
                message: `Detalles: Por favor, ingresa un correo electrónico válido.`,
            })
            return;
        }
        setError(null);

        try {
            isLoading(true);
            const lead = await axios.post(process.env.REACT_APP_BACKEND_SERVER + "/info/createLead",
                {
                    email: email,
                    source: "CATALOGO"
                });

            if (lead.status === 201) {
                showNotification({
                    color: 'green',
                    status: 'success',
                    title: 'Operación exitosa.',
                    message: `Recibirás el catálogo a la brevedad en tu casilla de correo.`,
                })
            } else {
                showNotification({
                    color: 'red',
                    status: 'error',
                    title: 'Operación fallida.',
                    message: `Detalles: No ha sido posible completar la operación.`,
                })
            }
        } catch (e) {
            console.log(e?.response?.data?.message || "")
        } finally {
            isLoading(false)
        }
    }


    const getCoursesData = () => {
        const url = process.env.REACT_APP_BACKEND_SERVER + `/info/academicUnits`;
        axios
            .get(url)
            .then((res) => {
                // Sort courses by date
                const openedCourses = res.data.open.map((_oCourse) => {
                    // Filter courses outsideCampus (openSource webinars)
                    if (_oCourse.comercialInformation.outsideCampus) return null;
                    const dateParts = _oCourse.comercialInformation.initialDate.split(" ")
                    const datePartsLower = dateParts.map(el => el.toLowerCase());
                    if (datePartsLower.includes("de")) {
                        datePartsLower.splice(datePartsLower.indexOf("de"), 1);
                    }

                    const month = MONTHS_DICT[datePartsLower[1].toLowerCase()];
                    const date = moment({
                        day: datePartsLower[0],
                        month: month,
                        year: datePartsLower[2]
                    });

                    return {
                        ..._oCourse,
                        startDate: date
                    }
                }).filter(el => el !== null)
                openedCourses.sort((a, b) => b.startDate - a.startDate);

                setCoursesData(openedCourses);
            })
            .catch((err) => {
                console.log(err)
                const errorMessage = errorHandler(err)
                showNotification({
                    color: 'red',
                    status: 'error',
                    title: 'Operación fallida.',
                    message: `Detalles: ${errorMessage}`,
                })
            })
    }

    useEffect(() => {
        if (!coursesData) getCoursesData();
    }, []);

    return (
        <MainLayout overflow="hidden">
            <div className="Catalog__container">
                <div className="Catalog__column">
                    <Stack p={0} gap={0} align="center">
                        <h1 className="Catalog__title">
                            Catálogo <br/>
                            <span className="Catalog__gradient">
                                de Cursos 2025
                        </span>
                        </h1>
                        <p className="Catalog__text">
                            Encuentra la capacitación que necesitas para potenciar tu carrera profesional.
                        </p>
                    </Stack>

                    <div className="Catalog__form">
                        <div className="Catalog__emailDiv">
                        <TextInput
                            placeholder="Ingresa tu correo electrónico"
                            value={email}
                            variant="filled"
                            onChange={(e) => {
                                if (error) setError(null);
                                setEmail(e.target.value)
                            }}
                            error={error}
                            w="100%"
                            size="lg"
                        />
                        </div>
                        <Button loading={loading} leftIcon={<IconDownload/>} color='var(--color-tertiary)' onClick={handleDownload} disabled={error}>
                            Recibir Catálogo
                        </Button>
                    </div>

                    <div className="Catalog__alternative">
                        <Button
                            onClick={() => navigate("/cursos")}
                        >
                            Ver próximos inicios en la web
                        </Button>
                    </div>
                </div>

                <div className="Catalog__column">
                    {coursesData && (
                        <Splide
                            options={{
                                type: "loop",
                                drag: "free",
                                focus: "center",
                                autoWidth: true,
                                gap: 40,
                                arrows: false,
                                pagination: false,
                                autoScroll: {
                                    speed: 1,
                                },
                            }}
                            extensions={{AutoScroll}}
                        >
                            {coursesData.map((item) => (
                                <SplideSlide>
                                    <GlassCard
                                        item={{
                                            ...item,
                                            photo: item.comercialInformation.image,
                                            name: item.comercialInformation.name,
                                        }}/>

                                </SplideSlide>
                            ))}
                        </Splide>
                    )}
                </div>
            </div>
        </MainLayout>
    );
}

export default Catalog;
