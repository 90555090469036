import { Flex } from '@mantine/core';
import './BlogCard.css'
import { Link } from 'react-router-dom'


const splitPostText = text => {
   return text.split('<br>')
}

const cleanString = (input) => {
   return input.replace(/<br\s*\/?>/gi, '');
};

export default function BlogCard({ isLatest, item, idx }) {

   if (isLatest) return (
      <Link
         className='BlogCard-latest-container'
         style={{ display: 'flex' }}
         to={`/blog/${item._id}`}
         key={item._id}
      >
         <article className='BlogCard-latest-wrapper'>
            <img
               className='BlogCard-latest-image'
               src={item.image}
               alt={item.title}
            />

            <div className='BlogCard-latest-contentWrapper'>
               <Flex align='center' justify='flex-start' gap={4}>
                  <span className='BlogCard-latest--date'>{item.date}</span>
                  {item.author && <span className='BlogCard-latest--date'>- por <strong>{item.author}</strong></span>}
               </Flex>


               <h2 className='BlogCard-latest--title'>{item.title}</h2>
               {
                  idx === 0
                     ? splitPostText(item.content)?.map(text => (
                        <p key={text} className='Blogcard-latest-description'>{text}</p>
                     ))
                     : <p className='Blogcard-latest-description'>{cleanString(item.content)}</p>
               }
            </div>
         </article>
      </Link>
   )

   return (
      <Link
         to={`/blog/${item._id}`}
         key={item._id
         } >
         <article className='BlogCard-general-container'>
            {item.image && <img
               className='BlogCard-general-image'
               src={item.image}
               alt={item.title}
            />}

            <div className='BlogCard-general-contentWrapper'>
               <Flex align='center' justify='flex-start' gap={4}>
                  <span className='BlogCard-latest--date'>{item.date}</span>
                  {item.author && <span className='BlogCard-latest--date'>- por <strong>{item.author}</strong></span>}
               </Flex>

               <h2 className='BlogCard-general--title'>{item.title}</h2>
               <p className='Blogcard-general-description'>{cleanString(item.content)}</p>
            </div>
         </article>
      </Link>
   )
}
