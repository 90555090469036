import { Button, Loader, TextInput, Textarea } from '@mantine/core'
import './SuggestForm.css'
import { useForm } from '@mantine/form';
import { useState } from 'react';
import axios from 'axios';
import { IconCheck, IconX } from '@tabler/icons-react';
import IconFilled from '../../../components/IconFilled/IconFilled';

export default function SuggestForm() {
   const [isLoading, setIsLoading] = useState(false)
   const [notification, setNotification] = useState({ status: '', message: '' })

   const form = useForm({
      validateInputOnChange: true,
      initialValues: {
         name: "",
         email: "",
         message: "",
      },
      validate: {
         name: (value) => ((!value || /^[A-Za-z\s]+$/.test(value)) && value.length <= 30 ? null : 'El nombre debe tener hasta 30 caracteres, y solo letras'),
         email: (value) => (!value || (/^\S+@\S+$/.test(value)) ? null : 'Ingrese un correo electrónico válido'),
         message: (value) => (/^[A-Za-z0-9\s.,!?'"(){}[\]:;-]+$/.test(value) && value.length > 10 ? null : 'El mensaje debe ser mayor a 10 caracteres y sin símbolos especiales.')
      },
   });

   const handleSubmit = async (values) => {
      const isValid = form.isValid()

      if (!isValid) return setNotification({ status: 'error', message: 'El mensaje no puede estar vacio' })

      try {
         setIsLoading(true)

         const { message: messageText, name, email } = values

         let message = 'Enviado desde sugerencias (anónimo): '

         if (name) {
            message = `Mensaje enviado por ${name} - `
         }

         if (name && email) {
            message = message.concat(`email: ${email} --- `)
         }

         if (!name && email) {
            message = `Mensaje enviado por ${email} --- `
         }

         message = message.concat(messageText)

         const data = {
            channel: 'web-soporte',
            message
         }

         await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/info/slack`, data)

         setNotification({ status: 'success', message: 'Mensaje enviado correctamente' })
         setTimeout(() => setNotification({ status: '', message: '' }), 2000)
         form.reset()

      } catch (err) {
         setNotification({ message: 'Lo siento, no hemos podido enviar tu mensaje', status: 'error' })
         setTimeout(() => setNotification({ status: '', message: '' }), 2000)
         console.log(err)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <div className='suggestForm-container'>
         <p>¿No encontraste lo que buscabas? Dejanos tu sugerencia a continuación</p>
         <form onSubmit={form.onSubmit((values) => handleSubmit(values))} className='suggestForm--form'>
            < TextInput
               label='Nombre:'
               placeholder="(opcional)"
               {...form.getInputProps("name")}
            />
            < TextInput
               label='Email:'
               placeholder="(opcional)"
               {...form.getInputProps("email")}
            />
            < Textarea
               withAsterisk
               label='Mensaje:'
               placeholder="Escribe tu mensaje..."
               disabled={isLoading}
               autosize
               minRows={4}
               maxRows={4}
               {...form.getInputProps("message")}
            />

            <Button type='submit' disabled={isLoading} variant='filled' bg='secondaryColor'>
               {isLoading ? < Loader size={20} color={'var(--color-white)'} /> : 'Enviar'}
            </Button>

         </form>
         {notification.message &&
            <div className='suggestForm-notification-container'>
               <IconFilled backgroundColor={notification.status !== 'error' ? 'green' : 'red'} rounded='var(--border-radius-lg)'>
                  {notification.status !== 'error' ? < IconCheck size={20} /> : < IconX />}
               </IconFilled>
               <p>{notification.message}</p>
            </div>
         }
      </div>
   )
}
