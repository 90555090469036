import { Skeleton } from '@mantine/core'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import React from 'react'

export default function HeroCarouselSkeleton() {
   return (
      <Splide
               options={{
                 type: 'loop',
                 drag: 'free',
                 focus: 'center',
                 autoWidth: true,
                 gap: 40,
                 arrows: false,
                 pagination: false,
                 autoScroll: {
                  speed: 1, 
               },
               }}
               extensions={{ AutoScroll }} 
            >
         <SplideSlide>
            < Skeleton style={{borderRadius: 'var(--border-radius-lg)'}} height={300} width={250} animated />
         </SplideSlide>
         <SplideSlide>
            < Skeleton style={{borderRadius: 'var(--border-radius-lg)'}} height={300} width={250} animated />
         </SplideSlide>
         <SplideSlide>
            < Skeleton style={{borderRadius: 'var(--border-radius-lg)'}} height={300} width={250} animated />
         </SplideSlide>
         <SplideSlide>
            < Skeleton style={{borderRadius: 'var(--border-radius-lg)'}} height={300} width={250} animated />
         </SplideSlide>
         <SplideSlide>
            < Skeleton style={{borderRadius: 'var(--border-radius-lg)'}} height={300} width={250} animated />
         </SplideSlide>
         <SplideSlide>
            < Skeleton style={{borderRadius: 'var(--border-radius-lg)'}} height={300} width={250} animated />
         </SplideSlide>
      </Splide>
   )
}