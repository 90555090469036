import AboutUsContainer from "../features/Inicio/About/AboutUsContainer/AboutUsContainer"
import AvalContainer from "../features/Inicio/Aval/AvalContainer/AvalContainer"
import LandingHero from "../features/Inicio/LandingHero/LandingHero";
import './Inicio.css'
import ContactBanner from "../features/Inicio/ContactBannerFolder/ContactBanner"
import TestimonialGrid from "../features/Inicio/TestimonialSectionFolder/TestimonialGrid/TestimonialGrid";
import SpecialtiesCarousel from "../features/Inicio/SpecialtiesCarouselFolder/SpecialtiesCarousel/SpecialtiesCarousel";
import NewsCarousel from "../features/Inicio/NewsCarouselFolder/NewsCarousel/NewsCarousel";
import TeamContainer from "../features/Inicio/Team/TeamContainer/TeamContainer";
import MainLayout from "../layouts/MainLayout/MainLayout";
import DecorativeCircle from "../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../components/TinyDecorativeCircle/TinyDecorativeCircle";
import SponsorSection from "../features/AboutUsPage/SponsorSection/SponsorSection";
import PromotionModal from "../components/PromotionModal/PromotionModal";
import {Button} from "@mantine/core";
import {IconExternalLink, IconPhone} from "@tabler/icons-react";

const Inicio = () => {
    // CHeck by date if the promotion modal should be active
    const isPromotionModalActive = new Date() <= new Date("2024-12-01") && new Date() >= new Date("2024-11-20");
    const handleContactSales = () => {
        window.location.href = "https://wa.me/5493518571139/?text=Hola!%20Quiero%20más%20información%20sobre%20la%20promo%20de%20Black%20Friday";
    };

    const handleDownloadPDF = () => {
        window.open("https://forvet-main.s3.sa-east-1.amazonaws.com/public/misc/cyber_monday_forvet.pdf", "_blank");
    };


    return (
        <MainLayout overflow="hidden">
            {isPromotionModalActive &&
                <PromotionModal
                    title="🎉 Black Friday - Del 20 al 30 de Noviembre 🎉"
                    message={null}
                    courses={[]}
                    image={"https://i.imgur.com/aqnK27X.jpeg"}
                    actions={
                        <>
                            <Button leftIcon={<IconPhone/>} onClick={handleContactSales} color="#DF3576">
                                Consultar a ventas
                            </Button>
                        </>
                    }
                />
            }

            <section className="landing__section" style={{position: "relative"}}>
                <LandingHero/>
                {/* <DecorativeCircle size={300} top={-200} left={-50} opacity={0.1} shadow={1}/>
                <DecorativeCircle size={200} top={-100} right={-100} opacity={0.2} shadow={4}/>
                <DecorativeCircle size={100} top={0} right={400} opacity={0.1} shadow={1}/>
                <DecorativeCircle size={150} bottom={-50} left={600} opacity={0.2} shadow={1}/>
                <DecorativeCircle size={200} bottom={-200} right={-200} opacity={0.2} shadow={2}/>
                <TinyDecorativeCircle size={12} top={100} left={700} opacity={.1} color={1}/>
                <TinyDecorativeCircle size={32} top={500} left={100} opacity={.1} color={3}/>
                <TinyDecorativeCircle size={32} top={400} right={100} opacity={.1} color={3}/>
                <TinyDecorativeCircle size={48} bottom={30} left={1000} opacity={.1} color={3}/> */}
            </section>

         <section className="landing__section--noPadding">
            <AvalContainer />
         </section>

         <section className="landing__section">
            <NewsCarousel />
         </section>

         <section className="landing__section">
            <SpecialtiesCarousel />
         </section>

            <section className="landing__section">
                < SponsorSection/>
            </section>

         <section className="landing__section">
            <AboutUsContainer />
         </section>

            <section className="landing__section">
                <TeamContainer/>
            </section>
            <section className="landing__section">
                <TestimonialGrid/>
            </section>
            <section className="landing__section">
                <ContactBanner/>
            </section>
        </MainLayout>
    )
}
export default Inicio