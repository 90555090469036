import React, { Suspense, lazy, useEffect, useState } from 'react';
import "./VideoPlayerSection.css";
import {Loader} from "@mantine/core";
const LazyVideo = lazy(() => import('../LazyVideo/LazyVideo'));

const VideoPlayerSection = () => {
  const counts = [
    {
      id: "001",
      label: "Cursos Dictados",
      number: "+200",
      duration: "3",
    },
    {
      id: "002",
      label: "Profesionales",
      number: "+100",
      duration: "3",
    },
    {
      id: "003",
      label: "Alumnos",
      number: "+2000",
      duration: "5",
    },
  ];

  const Count = (props) => {
    const { id, number, duration } = props.data;
    const [count, setCount] = useState("0");

    useEffect(() => {
      let start = 0;
      const end = parseInt(number.substring(1));
      if (start === end) return;

      let totalMilSecDur = parseInt(duration);
      let incrementTime = (totalMilSecDur / end) * 1000;

        let timer = setInterval(() => {
          // Ajusta el incremento basado en el id
          if (id === "003") {
            start += 10; // Incremento específico para el objeto con id "003"
          } else {
            start += 1; // Incremento predeterminado para otros objetos
          }
        setCount("+" + String(start));
        if (start === end) clearInterval(timer);
      }, incrementTime);
      
    }, [number, duration]);

    return (
      <div>
        <h2 className="videoPlayerSection-stats-number-styling">{count}</h2>
      </div>
    );
  };

  return (
    <div className="video-player-container">
      <div className="videoPlayerSection-iframe-div-container">
        <Suspense fallback={<Loader/>}>
          <LazyVideo 
            src="https://forvet-main.s3.sa-east-1.amazonaws.com/public/videos/institutional_web.mp4"
            poster="https://forvet-main.s3.sa-east-1.amazonaws.com/public/videos/institutional_web_poster.png"
         />
        </Suspense>
      </div>
      <div className="videoPlayerSection-stats-div-container">
        {counts.map((data) => (
          <div className="videoPlayerSection-map-styling-mobile" key={data.id}>
            <h2 className="videoPlayerSection-stats-number-styling">
              <Count data={data} />
            </h2>
            <p className="videoPlayerSection-stats-description-styling">
              {data.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoPlayerSection;
