import { Flex, Skeleton, Stack } from '@mantine/core'
import React from 'react'

export default function EliteCarSkeleton() {
   return (
      <Stack>
         <Skeleton height={32} style={{opacity: .1, borderRadius: 'var(--border-radius-lg)'}} animated/>
         <Flex align='center' gap={16}>
            <Skeleton width={55} height={32} style={{opacity: .1, borderRadius: 'var(--border-radius-lg)'}} animated/>
            <Skeleton width={75} height={32} style={{opacity: .1, borderRadius: 'var(--border-radius-lg)'}} animated/>
         </Flex>
         <Skeleton width={175} height={15} style={{opacity: .1, borderRadius: 'var(--border-radius-lg)'}} animated/>
         <Skeleton width={'100%'} height={400} style={{opacity: .1, borderRadius: 'var(--border-radius-lg)'}} animated/>
         <Skeleton width={150} height={50} style={{opacity: .1, borderRadius: 'var(--border-radius-lg)'}} animated/>

      </Stack>
   )
}
