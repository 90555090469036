import "./CoursesOffers.css";
import {Pagination} from "@mantine/core";
import SpecialtiesCardsContainer from "../../Specialties/SpecialtiesCardsContainer/SpecialtiesCardsContainer";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useAppContext} from "../../../context/AppState/AppContext";
import SuggestForm from "../SuggestForm/SuggestForm";
import {calculatePrice} from "../../../utils/calculatePrice";
import CardInfoColumn from "../../../components/CardInfo/CardInfoColumn/CardInfoColumn";

const CoursesOffers = ({data, upcomingCourses, displayUpcomingCourses, isLoading, activePage, setPage, totalPages, finishedCourses}) => {
    const navigate = useNavigate();
    const {isUSD, officialUSDValue} = useAppContext()

    useEffect(() => {
        setPage(1);
    }, [setPage, totalPages]);

    return (
        <div className="course-offers-container">
            {(!isLoading && (data === null || data?.length === 0)) &&
            < SuggestForm/>
            }

            {(!isLoading && upcomingCourses?.length > 0 && displayUpcomingCourses) &&
            <div style={{marginBottom: "48px"}}>
                <SpecialtiesCardsContainer sectionTitle="Próximos inicios">

                    {upcomingCourses?.map((item) => {
                        const {name, image, initialDate, price: prices, classQuantity} = item.comercialInformation;
                        const {specialties, type, _id} = item;
                        const price = calculatePrice(prices, officialUSDValue, isUSD, type);
                        return (
                            <CardInfoColumn
                                key={item._id}
                                imgUrl={image}
                                type={type}
                                tag={specialties}
                                title={name.toLowerCase()}
                                price={price}
                                booleanValue
                                classes={classQuantity}
                                date={initialDate}
                                buttonText="Ver curso"
                                buttonAction={() => navigate(`/curso/${_id}`)}
                            />
                        );
                    })}
                </SpecialtiesCardsContainer>
            </div>
            }
            {(!isLoading && data?.length > 0) &&
            <SpecialtiesCardsContainer sectionTitle="Todas las inscripciones abiertas">

                {data?.map((item) => {
                    const {name, image, initialDate, price: prices, classQuantity} = item.comercialInformation;
                    const {specialties, type, _id} = item;
                    const price = calculatePrice(prices, officialUSDValue, isUSD, type);
                    return (
                        <CardInfoColumn
                            key={item._id}
                            imgUrl={image}
                            type={type}
                            tag={specialties}
                            title={name.toLowerCase()}
                            price={price}
                            booleanValue
                            classes={classQuantity}
                            date={initialDate}
                            rowIn={805}
                            buttonText="Ver curso"
                            buttonAction={() => navigate(`/curso/${_id}`)}
                        />
                    );
                })}
            </SpecialtiesCardsContainer>
            }

            <div className="coursesOffers-pagination-div-container" style={{marginBottom: '30px'}}>
                <Pagination value={activePage} onChange={setPage} total={totalPages}/>
            </div>

            {(!isLoading && finishedCourses?.length > 0) &&
            <SpecialtiesCardsContainer sectionTitle="Cursos finalizados">

                {finishedCourses?.map((item) => {
                    const {name, image} = item.comercialInformation;
                    const {specialties, type, _id} = item;

                    return (
                        <CardInfoColumn
                            key={item._id}
                            imgUrl={image}
                            type={type}
                            tag={specialties}
                            title={name.toLowerCase()}
                            rowIn={805}
                            buttonText="ver más"
                            buttonAction={() => navigate(`/curso/${_id}`)}
                        />
                    );
                })}
            </SpecialtiesCardsContainer>
            }
        </div>
    );
};
export default CoursesOffers;
