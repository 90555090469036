import IconCardText from '../../../components/IconCardText/IconCardText';
import IconFilled from '../../../components/IconFilled/IconFilled';
import SectionTitle from '../../../components/SectionTitle/SectionTitle'
import './CourseBenefits.css'
import {IconBuilding, IconCertificate, IconUsersGroup, IconUserStar} from "@tabler/icons-react";

const COURSE_BENEFITS_INFO = [
    {
        icon: <IconCertificate className="course-benefits-icon"/>,
        text: 'Certificados de Especialización',
        description: 'Obtén certificados de finalización al concluir con éxito nuestros cursos de veterinaria.'
    },
    {
        icon: <IconUserStar className="course-benefits-icon"/>,
        text: 'Instrucción de Expertos',
        description: 'Aprende de profesionales de renombre en el campo de la veterinaria y la atención animal.'
    },
    {
        icon: <IconBuilding className="course-benefits-icon"/>,
        text: 'Validación de Instituciones Veterinarias',
        description: 'Nuestro contenido está respaldado y validado por instituciones líderes en medicina veterinaria.'
    },
    {
        icon: <IconUsersGroup className="course-benefits-icon"/>,
        text: 'Comunidad Veterinaria',
        description: 'Únete a nuestra comunidad exclusiva de estudiantes y profesionales de la veterinaria.'
    }
]

export default function CourseBenefits() {

    return (
        <div className='course-benefits-container'>
            <div className='course-benefits-heading'>
                < SectionTitle upText='beneficios' downText='Aprendiendo con FORVET'/>
                <p className='course-benefits-description'>
                    Descubre los beneficios de nuestros cursos de especialización en veterinaria. Obtén certificados,
                    aprende de expertos, accede a material de calidad y únete a nuestra comunidad veterinaria.
                </p>
            </div>
            <div className='course-benefits-information'>
                {
                    COURSE_BENEFITS_INFO.map((item, idx) => (
                        <IconCardText
                            key={idx} text={item.text}
                            description={item.description}
                            minHeight={184}
                        >
                            <IconFilled
                                backgroundColor='var(--color-secondary)'
                                rounded='var(--border-radius-lg)'
                            >
                                {item.icon}
                            </IconFilled>
                        </IconCardText>
                    ))
                }
            </div>
        </div>
    )
}
