import { IconArrowRight, IconChevronRight, IconFlag, IconMapPin, IconStarFilled } from '@tabler/icons-react';
import './EliteCard.css'
import LazyVideo from '../../features/AboutUsPage/LazyVideo/LazyVideo'
import { Badge, Button, Flex, Loader, Modal, Stack } from '@mantine/core';
import { useTransform, motion } from 'framer-motion';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const FAKE_TEACHERS = [
   {
      nationality: 'Argentina',
      city: 'Larroque',
      about: {
         personalItems: [
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }
         ],
         description: 'una descripcion de lo que hace el profesor'
      },
      _id: '1',
      name: 'Antonino',
      lastname: 'Sartori',
      profilePicture: 'https://img.freepik.com/foto-gratis/jolly-excito-apuesto-especialista-masculino-trabajando-tableta-mirando-camara_1262-14260.jpg?t=st=1729697075~exp=1729700675~hmac=9f486a6c09adb7aee54f03709e3966ab225235119be5a62da599f8241e6f81cf&w=1380'
   },
   {
      nationality: 'Argentina',
      city: 'Larroque',
      about: {
         personalItems: [
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            },
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }, {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }, {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }, {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }
         ],
         description: 'una descripcion de lo que hace el profesor. una descripcion de lo que hace el profesor. una descripcion de lo que hace el profesor. una descripcion de lo que hace el profesor. una descripcion de lo que hace el profesor. una descripcion de lo que hace el profesor'
      },
      _id: '2',
      name: 'Federico',
      lastname: 'Ambroggio',
      profilePicture: 'https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg?t=st=1729775783~exp=1729779383~hmac=c95cc3d0e159f3acc83b0cb5dc6e2e2f51137ca5d6504b78296e5f731be89ca8&w=1380'
   },
   {
      nationality: 'Argentina',
      city: 'Larroque',
      about: {
         personalItems: [
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }
         ],
         description: 'una descripcion de lo que hace el profesor'
      },
      _id: '3',
      name: 'Felipe',
      lastname: 'Polzella Ciuffolini',
      profilePicture: 'https://img.freepik.com/foto-gratis/apuesto-joven-brazos-cruzados-sobre-fondo-blanco_23-2148222620.jpg?t=st=1729775804~exp=1729779404~hmac=6ba3594f6013e09948b8dc0060a85244cd376e51844b1ed4a654dbe5469e8053&w=826'
   },
   {
      nationality: 'Argentina',
      city: 'Larroque',
      about: {
         personalItems: [
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }
         ],
         description: 'una descripcion de lo que hace el profesor'
      },
      _id: '4',
      name: 'Juan',
      lastname: 'Casagrande',
      profilePicture: 'https://img.freepik.com/foto-gratis/jolly-excito-apuesto-especialista-masculino-trabajando-tableta-mirando-camara_1262-14260.jpg?t=st=1729697075~exp=1729700675~hmac=9f486a6c09adb7aee54f03709e3966ab225235119be5a62da599f8241e6f81cf&w=1380'
   },
   {
      nationality: 'Argentina',
      city: 'Larroque',
      about: {
         personalItems: [
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }
         ],
         description: 'una descripcion de lo que hace el profesor'
      },
      _id: '5',
      name: 'Jose',
      lastname: 'Perez',
      profilePicture: 'https://img.freepik.com/foto-gratis/jolly-excito-apuesto-especialista-masculino-trabajando-tableta-mirando-camara_1262-14260.jpg?t=st=1729697075~exp=1729700675~hmac=9f486a6c09adb7aee54f03709e3966ab225235119be5a62da599f8241e6f81cf&w=1380'
   },
   {
      nationality: 'Argentina',
      city: 'Larroque',
      about: {
         personalItems: [
            {
               item: 'Mascota preferida',
               value: "Perro"
            },
            {
               item: "Color favorito",
               value: 'rojo'
            }
         ],
         description: 'una descripcion de lo que hace el profesor'
      },
      _id: '6',
      name: 'Maxi',
      lastname: 'Lopez',
      profilePicture: 'https://img.freepik.com/foto-gratis/jolly-excito-apuesto-especialista-masculino-trabajando-tableta-mirando-camara_1262-14260.jpg?t=st=1729697075~exp=1729700675~hmac=9f486a6c09adb7aee54f03709e3966ab225235119be5a62da599f8241e6f81cf&w=1380'
   }


]

function capitalizeText(text) {
   let lowercase = text.toLowerCase();

   let sentences = lowercase.split('.');

   let result = sentences.map(sentence => {
      let sencenceTrimmed = sentence.trim();
      return sencenceTrimmed.charAt(0).toUpperCase() + sencenceTrimmed.slice(1);
   });

   return result.join('. ');
}

function getFirstString(string) {
   if (!string) return ''
   return string.split(' ')[0]
}


export default function EliteCard({ scrollYProgress, position, numItems, item }) {

   const [isTeacherModal, setIsTeacherModal] = useState(false)
   const [hasScrollbar, setHasScrollbar] = useState(false);
   const scrollWrapperRef = useRef(null);

   const checkScroll = () => {
      const element = scrollWrapperRef.current;
      if (element && element.scrollWidth > element.clientWidth) {
         setHasScrollbar(true);
      } else {
         setHasScrollbar(false);
      }
   };

   useEffect(() => {
      checkScroll();
      window.addEventListener('resize', checkScroll);

      return () => {
         window.removeEventListener('resize', checkScroll);
      };
   }, []);

   const stepSize = 1 / numItems;
   const end = stepSize * position;
   const start = end - stepSize;

   const opacity = useTransform(scrollYProgress, [start, end], [1, 0]);
   const scale = useTransform(scrollYProgress, [start, end], [1, 0.75]);


   return (
      <>
         <motion.div
            style={{
               opacity,
               scale,
            }}
            className="EliteCard-main-container"
         >
            <Stack align='start' w='100%' p={0} gap={0}>
               <h3 className='EliteCard--title'>{item.comercialInformation.name}</h3>
            </Stack>
            {item.comercialInformation?.video
               ? <Suspense fallback={<Loader />}>
                  <LazyVideo
                     src={item.comercialInformation.video}
                  />
               </Suspense>
               : <figure className="EliteCard-image-container">
                  <img className="EliteCard--image" src={item.comercialInformation.image} alt={item.comercialInformation.name} />
               </figure>
            }

            <Stack w='100%' align='start' gap={16}>
               <h3 className='EliteCard--title-teacher'>Coordinada por</h3>
               <Stack w='100%' align='start' gap={0}>
                  <div ref={scrollWrapperRef} className='EliteCard-teacher-wrapper'>
                     {item.director.map(_dir => (
                        <article onClick={() => setIsTeacherModal(_dir)} key={_dir._id} className='VerticalCard-main-container'>
                           <figure className='VerticalCard--background-image-container'>
                              <img className='VerticalCard--background-image' alt={`${_dir.name} ${_dir.lastname}`} src={_dir.profilePicture} />
                           </figure>
                           <img className='VerticalCard--image' alt={`${_dir.name} ${_dir.lastname}`} src={_dir.profilePicture} />
                           <h3 className='VerticalCard--name'>{getFirstString(_dir.name)} {getFirstString(_dir.lastname)}</h3>
                        </article>
                     ))}
                  </div>
                  {hasScrollbar && <Flex align='center'>
                     <small className='EliteCard-teacher-scroll-text'>DESLIZA PARA VER TODOS LOS PROFESORES</small>
                     <IconArrowRight size={16} />
                  </Flex>
                  }
               </Stack>
            </Stack>

            <Button component={Link} to={`/curso/${item._id}`} color='#BF953F' size='xl'>¡Lo quiero!</Button>
         </motion.div>

         {isTeacherModal &&
            <Modal
               styles={{ body: { backgroundColor: '#131313' } }}
               overlayProps={{
                  backgroundOpacity: .8,
               }}
               size="auto"
               centered
               withCloseButton={false}
               opened={isTeacherModal}
               onClose={() => setIsTeacherModal(null)}
            >
               <Flex direction={{ base: 'column', sm: 'row' }} align='start' justify='space-between' gap={32}>
                  <Stack>
                     <h3 className='EliteCard-TeacherModal--name'>{isTeacherModal.name} {isTeacherModal.lastname}</h3>

                     <div className='EliteCard-TeacherModal-locationWrapper'>
                        <div className='EliteCard-TeacherModal--group' >
                           <IconFlag color='#BF953F' />
                           <span className='EliteCard-TeacherModal-locationText'>{isTeacherModal.nationality}</span>
                        </div>
                        <div className='EliteCard-TeacherModal--group'>
                           <IconMapPin color='#BF953F' />
                           <span className='EliteCard-TeacherModal-locationText'>{isTeacherModal.city}</span>
                        </div>
                     </div>

                     {isTeacherModal?.about?.description && <p className='EliteCard-TeacherModal--description'>{isTeacherModal?.about?.description}</p>}
                     {isTeacherModal?.about?.personalItems &&
                        <Stack>
                           {isTeacherModal?.about?.personalItems?.map(item => (
                              <Flex align='center' gap={4}>
                                 <IconChevronRight color='#BF953F' />
                                 <h4 className='EliteCard-TeacherModal--personalItemLabel'>{item.item}:</h4>
                                 <span className='EliteCard-TeacherModal--personalItemValue'>{item.value}</span>
                              </Flex>
                           ))}
                        </Stack>
                     }
                  </Stack>
                  <figure className='EliteCard-TeacherModal-image-container' >
                     <img className='EliteCard-TeacherModal--image' alt={`${isTeacherModal.name} ${isTeacherModal.lastname}`} src={isTeacherModal.profilePicture} />
                  </figure>
               </Flex>
               <Flex justify='end'>
                  <Button w='max-content' onClick={() => setIsTeacherModal(null)} mt={16} color='#BF953F'>Aceptar</Button>
               </Flex>
            </Modal>
         }
      </>
   );
}

