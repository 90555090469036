import CardInfoRow from "./CardInfoRow/CardInfoRow";
import CardInfoColumn from "./CardInfoColumn/CardInfoColumn";
import { useAppContext } from "../../context/AppState/AppContext";

/* 

Componente CardInfo generico: Usa CardInfoColumn hasta los pixeles por defectos o dados, y luego usa el CardInfoRow

- Dos opciones de componente que se pueden usar individualmente.
   ✅ CardInfoRow 
   ✅ CardInfoColumn 

💡 Se puede estilar los estilos en caso particular usando al contenedor padre
 Ejemplo: 

html
   <div className='parent-container'>
   < CardInfoRow  />
   </div>

css
   .parent-container .gridInfo-row{
      width: 150px;
      backgroundColor: red;
   }
*/

export default function CardInfo({
  classes = 8,
  price = 1000,
  date = "15 sep",
  imgUrl,
  imgAlt = "imagen_curso",
  title,
  tag,
  type,
  buttonText = "Más",
  buttonColor = "dark",
  buttonVariant = "filled",
  booleanValue = false,
  rowIn = 768,
  rowOut = 470,
  buttonAction,
}) {
   const { width } = useAppContext()
   const isMobile = width <= rowIn && width >= rowOut;
   
   return (
      <>
         {isMobile ? (
            <CardInfoRow
               classes={classes}
               price={price}
               date={date}
               imgUrl={imgUrl}
               imgAlt={imgAlt}
               title={title}
               tag={tag}
               type={type}
               buttonText={buttonText}
               buttonColor={buttonColor}
               buttonVariant={buttonVariant}
               booleanValue={booleanValue}
               buttonAction={buttonAction}
            />
         ) : (
            <CardInfoColumn
               classes={classes}
               price={price}
               date={date}
               imgUrl={imgUrl}
               imgAlt={imgAlt}
               title={title}
               tag={tag}
               type={type}
               buttonText={buttonText}
               buttonColor={buttonColor}
               buttonVariant={buttonVariant}
               booleanValue={booleanValue}
               buttonAction={buttonAction}
            />
         )}
      </>
   );
}
