import { Link } from "react-router-dom";
import ChevronDown from '../../../assets/ChevronDown';
import './NavLink.css'
import useEliteStatus from "../../../hooks/useEliteStatus";

export default function NavLink({ text = '', to = '/', withMenu, onClick, children, style, ...others }) {

   const isElite = useEliteStatus()

   return (
      !withMenu ? <Link style={style ?? {}} className={isElite ? "navLink navLink--elite" : "navLink"} to={to} >{text}</Link>
         :
         <>
            <li style={style ?? {}} onClick={onClick ?? null} className='mega-menu-chevron-group' {...others}>
               <div className={isElite ? "mega-menu-chevron-row mega-menu-chevron-row--elite" : "mega-menu-chevron-row"}>
                  <span className={isElite ? "navLink navLink--elite" : "navLink"}>{text}</span>
                  < ChevronDown />
               </div>
               {children}
            </li>

         </>
   )
}