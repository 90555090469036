import './AboutUsPage.css'
import MainLayout from "../../layouts/MainLayout/MainLayout"
import AboutUsHero from '../../features/AboutUsPage/AboutUsHero/AboutUsHero'
import MissionSection from '../../features/AboutUsPage/MissionSection/MissionSection'
import VisionSection from '../../features/AboutUsPage/VisionSection/VisionSection'
import PhilosophySection from '../../features/AboutUsPage/PhilosophySection/PhilosophySection'
import ValuesSection from '../../features/AboutUsPage/ValuesSection/ValuesSection'
import SponsorSection from '../../features/AboutUsPage/SponsorSection/SponsorSection'
import TeamContainer from '../../features/Inicio/Team/TeamContainer/TeamContainer'
import VideoPlayerSection from '../../features/AboutUsPage/VideoPlayerSection/VideoPlayerSection'
import DecorativeCircle from "../../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../../components/TinyDecorativeCircle/TinyDecorativeCircle";

const AboutUsPage = () => {

   return (
      <MainLayout overflow="hidden">

         <section style={{position: "relative"}} className="aboutUsPage-section aboutUsPage-hero--padding aboutUsPage-hero-container">
            < AboutUsHero />
             <DecorativeCircle size={100} top={-20} left={-20} opacity={0.1} shadow={1}/>
             <DecorativeCircle size={150} bottom={-220} left={300} opacity={0.1} shadow={1}/>
             <DecorativeCircle size={50} top={-20} left={800} opacity={0.1} shadow={4}/>
             <DecorativeCircle size={100} top={-50} right={-50} opacity={0.1} shadow={2}/>
             <TinyDecorativeCircle size={12} top={50} right={350} opacity={.1} color={1}/>
             <TinyDecorativeCircle size={12} top={300} left={70} opacity={0.1} color={1}/>
             <TinyDecorativeCircle size={32} top={80} left={500} opacity={.1} color={3}/>
         </section>

         <section className='aboutUsPage-section'>
            <VideoPlayerSection />
         </section>

          <section className='aboutUsPage-section'>
              < SponsorSection />
          </section>

          <section className='aboutUsPage-section aboutUsPage-vision-mission-section'>
            < MissionSection />
         </section>

         <section className='aboutUsPage-section aboutUsPage-vision-mission-section'>
            < VisionSection />
         </section>

         <section className='aboutUsPage-section'>
            < ValuesSection />
         </section>

         <section className='aboutUsPage-section aboutUsPage-vision-mission-section'>
            < PhilosophySection />
         </section>

         {/* <section className='aboutUsPage-section'>
            < TeamContainer />
         </section> */}

      </MainLayout>
   )
}
export default AboutUsPage