import { Link } from 'react-router-dom'
import forvetLogo from '../../../assets/logo-forvet-dark.svg'
import forvetLogoElite from '../../../assets/fv_logo-golden.png'
import useEliteStatus from '../../../hooks/useEliteStatus'

export default function HeaderLogo() {
   const isElite = useEliteStatus()

   const logo = isElite ? forvetLogoElite : forvetLogo

   return (
      <Link className='logo-container' to='/'>
         <img src={logo} alt="forvet" />
      </Link>
   )
}
