import { Routes, Route, BrowserRouter } from "react-router-dom";
import Inicio from "../pages/Inicio";
import Contact from "../pages/Contact/Contact";
import Specialties from "../pages/Specialties";
import CourseDetails from "../pages/CourseDetails";
import CoursesPage from "../pages/CoursesPage/CoursesPage";
import AboutUsPage from "../pages/AboutUs/AboutUsPage";
import CoursePaymentPage from "../pages/CoursePaymentPage/CoursePaymentPage";
import PaymentCheckout from "../pages/PaymentCheckout/PaymentCheckout";
import TransferBankPage from "../pages/TransferBankPage/TransferBankPage";
import { ScrollToTop } from 'react-router-scroll-to-top';
import Error404Page from "../pages/ErrorPage/Error404Page";
import PreguntasFrecuentes from "../pages/PreguntasFrecuentes/PreguntasFrecuentes";
import Registry from "../pages/Campus/Registry";
import useAckee from "use-ackee";
import Admin from "../pages/Admin/Admin";
import EliteOffer from "../pages/EliteOffer/EliteOffer";
import BlogPage from "../pages/Blog/BlogPage";
import BlogDetails from "../pages/BlogDetails/BlogDetails";
import Catalog from "../pages/Catalog/Catalog";

const AppRoutes = () => {
   useAckee(
      window.location.pathname,
      {
         server: 'https://ackee.forvet.org',
         domainId: 'eb820240-42a1-435b-a884-c7870ee43bd3'
      },
      {
         detailed: true,
         ignoreLocalhost: true,
         ignoreOwnVisits: true
      }
   );
   return (
      <>
         <BrowserRouter>
            <ScrollToTop />
            <Routes>
               <Route exact path="/" element={<Inicio />} />
               <Route exact path="/catalogo" element={<Catalog />} />
               <Route path="/institucional" element={<AboutUsPage />} />
               <Route path="/contacto" element={<Contact />} />
               <Route
                  path="/especializaciones/:specialtyId"
                  element={<Specialties />}
               />
               <Route path="/curso/:id" element={<CourseDetails />} />
               <Route path="/cursos" element={<CoursesPage />} />
               <Route path="/procesando-pago/" element={<PaymentCheckout />} />
               <Route path="/pago" element={<CoursePaymentPage />} />
               <Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
               <Route path="/transferencia-bancaria" element={<TransferBankPage />} />
               <Route path="*" element={<Error404Page />} />
               <Route path="/campus" element={<Registry />} />
               <Route path="/admin" element={<Admin />} />
               <Route path="/oferta-elite" element={<EliteOffer />} />
               <Route path="/blog" element={<BlogPage />} />
               <Route path="/blog/:postId" element={<BlogDetails />} />

            </Routes>
         </BrowserRouter>
      </>
   );
};
export default AppRoutes;
