import {createContext, useContext, useState} from "react";
import {useFetch} from "../../hooks/useFetch";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useUserLocation from "../../hooks/useUserLocation";

const AppContext = createContext()

export const useAppContext = () => {
    return useContext(AppContext)
}

export const AppProvider = ({children}) => {
    // consts
    const {width} = useWindowDimensions()
    const isMobile = width <= 768
    const [paymentData, setPaymentData] = useState(null)


    // getting user location
    const {getUserLocation, isUSD, permissionModal, setPermissionModal} = useUserLocation()

    // fetching data
    const specialtiesInfo = useFetch(`${process.env.REACT_APP_BACKEND_SERVER}/info/specialties/`)
    const openSource = useFetch(`${process.env.REACT_APP_BACKEND_SERVER}/info/openSource`)
    const officialUSDResponse = useFetch(`https://dolarapi.com/v1/dolares/bolsa`)
    const officialUSDValue = officialUSDResponse?.data?.venta || null

    return (
        <AppContext.Provider value={{
            getUserLocation,
            setPaymentData,
            setPermissionModal,
            specialtiesInfo,
            openSource,
            paymentData,
            permissionModal,
            width,
            isMobile,
            isUSD,
            officialUSDValue,
        }}>
            {children}
        </AppContext.Provider>
    )
}