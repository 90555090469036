import { useNavigate, useParams } from 'react-router-dom'
import './BlogDetails.css'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import { useEffect, useState } from 'react';
import { Button, Flex, Loader, Stack } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

const FAKE_DATA = [
   {
      _id: "1a2b3c4d5e",
      date: "2024-10-01",
      title: "Exploring the Future of AI",
      description: "An insightful look into how artificial intelligence is shaping the future of technology and society. An insightful look into how artificial intelligence is shaping the future of technology and society.<br>An insightful look into how artificial intelligence is shaping the future of technology and society. <br>An insightful look into how artificial intelligence is shaping the future of technology and society. An insightful look into how artificial intelligence is shaping the future of technology and society.",
      imageUrl: "https://img.freepik.com/foto-gratis/fondo-textura-hoja-verde_501050-120.jpg?semt=ais_hybrid"
   },
   {
      _id: "2b3c4d5e6f",
      date: "2024-09-25",
      title: "Investigaciones Recientes en Nutrición Animal",
      description: "Un análisis de las últimas investigaciones en nutrición animal y cómo estas pueden mejorar la salud y el rendimiento de nuestras mascotas. La nutrición adecuada es fundamental para el desarrollo y la salud a largo plazo de los animales.<br>Conocer los nutrientes esenciales y sus beneficios es clave para los veterinarios.",
      imageUrl: "https://img.freepik.com/free-photo/view-adorable-dog-with-bowl-food_23-2150798720.jpg?ga=GA1.1.1900990210.1730379746&semt=ais_hybrid"
   },
   {
      _id: "3c4d5e6f7g",
      date: "2024-09-18",
      title: "The Evolution of Web Development",
      description: "A comprehensive review of how web development has <br>evolved in recent years, including new frameworks and tools.",
      imageUrl: "https://img.freepik.com/foto-gratis/pequeno-tronco-rio-selva-tropical_1150-13681.jpg?semt=ais_hybrid"
   },
   {
      _id: "41d5e6f7g8h",
      date: "2024-09-10",
      title: "Understanding Quantum Computing",
      description: "An introduction to quantum computing, its principles, <br>and its potential impact on various industries.",
      imageUrl: "https://img.freepik.com/foto-gratis/fondo-hojas-verdes-tropicales_53876-88891.jpg?semt=ais_hybrid"
   },
   {
      _id: "5e6f71g8h9i",
      date: "2024-09-01",
      title: "The Rise of Remote Work",
      description: "How remote work has transformed the way companies <br>operate and what it means for the future of work.",
      imageUrl: "https://img.freepik.com/foto-gratis/hermoso-arbol-medio-campo-cubierto-hierba-linea-arboles-fondo_181624-29267.jpg?semt=ais_hybrid"
   },
   {
      _id: "1a2b31c4d5e",
      date: "2024-10-01",
      title: "Exploring the Future of AI",
      description: "An insightful look into how artificial intelligence <br>is shaping the future of technology and society.",
      imageUrl: "https://img.freepik.com/fotos-premium/primer-plano-planta-verde-fresca-luz-sol_1048944-25346240.jpg?semt=ais_hybrid"
   },
   {
      _id: "2b31c4d5e6f",
      date: "2024-09-25",
      title: "Sustainable Living: Tips for a Greener Life",
      description: "Learn practical tips for reducing your <br>environmental footprint and living a more sustainable lifestyle.",
      imageUrl: "https://img.freepik.com/foto-gratis/salida-sol-sobre-selva-bali_1385-1644.jpg?semt=ais_hybrid"
   },
   {
      _id: "3c4d5e6f71g",
      date: "2024-09-18",
      title: "The Evolution of Web Development",
      description: "A comprehensive review of how web development has evolved in recent years, including new <br>frameworks and tools.",
      imageUrl: "https://img.freepik.com/foto-gratis/cerrar-hojas-verdes-naturaleza_23-2149005996.jpg?semt=ais_hybrid"
   },
   {
      _id: "4d5e36f7g8h",
      date: "2024-09-10",
      title: "Understanding Quantum Computing",
      description: "An introduction to quantum computing, its principles, <br>and its potential impact on various industries.",
      imageUrl: "https://example.com/images/quantum-computing.jpg"
   },
   {
      _id: "5e6f7g58h9i",
      date: "2024-09-01",
      title: "The Rise of Remote Work",
      description: "How remote work has transformed the way companies operate <br>and what it means for the future of work.",
      imageUrl: "https://img.freepik.com/foto-gratis/tiro-macro-vena-hoja_23-2147836292.jpg?semt=ais_hybrid"
   }
];

export default function BlogDetails() {
   const navigate = useNavigate()
   const { postId } = useParams()

   const [post, setPost] = useState(null)

   useEffect(() => {
      const getPost = async () => {
         // TO DO fetch
         const searchedByID = FAKE_DATA.find(item => item._id === postId)
         setPost(searchedByID)
      }

      getPost()
   }, [postId])

   const postText = post?.description.split('<br>')



   return (
      <MainLayout>
         <section className='BlogDetails-main-container'>
            {!post || post?.length === 0
               ? <Loader />
               : <>

                  <Button ml='var(--layout-padding-x)' w='max-content' variant='outline' onClick={() => navigate('/blog')}>
                     <IconArrowLeft />
                     <span>Volver al blog</span>
                  </Button>

                  <h1 className='BlogPage--pageTitle'>{post.title}</h1>

                  <div className='BlogDetails-content-wrapper'>
                     <Flex align='center' justify='flex-start' gap={4}>
                        <span className='BlogCard-latest--date'>{post.date}</span>
                        {post.author && <span className='BlogCard-latest--date'>- por <strong>{post.author}</strong></span>}
                     </Flex>


                     {post?.imageUrl && <img className='BlogDetails--image' src={post.imageUrl} alt={post.title} />}

                     <Stack align='start' gap={8}>
                        {postText?.map(text => (
                           <p className='Blogcard-latest-description' key={text}>{text}</p>

                        ))}
                     </Stack>

                  </div>
               </>
            }

         </section>
      </MainLayout>
   )
}
