import './BlogPage.css'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import BlogCard from '../../components/Blog/BlogCard/BlogCard';

const FAKE_DATA = [
   {
      _id: "1a2b3c4d5e",
      date: "2024-10-01",
      title: "Avances en Medicina Veterinaria Preventiva",
      content: "Exploramos las últimas investigaciones en medicina veterinaria preventiva y su impacto en la salud animal. Conoceremos las estrategias más efectivas para prevenir enfermedades y mejorar el bienestar de nuestras mascotas.<br>La medicina preventiva es clave para asegurar una vida saludable y longeva para nuestros animales.",
      image: "https://img.freepik.com/premium-photo/veterinarian-puts-catheter-cat-veterinary-hospital-emergency-care-animals_926199-4281176.jpg?w=360"
   },
   {
      _id: "2b3c4d5e6f",
      date: "2024-09-25",
      title: "Investigaciones Recientes en Nutrición Animal",
      content: "Un análisis de las últimas investigaciones en nutrición animal y cómo estas pueden mejorar la salud y el rendimiento de nuestras mascotas. La nutrición adecuada es fundamental para el desarrollo y la salud a largo plazo de los animales.<br>Conocer los nutrientes esenciales y sus beneficios es clave para los veterinarios.",
      image: "https://img.freepik.com/free-photo/view-adorable-dog-with-bowl-food_23-2150798720.jpg?ga=GA1.1.1900990210.1730379746&semt=ais_hybrid"
   },
   {
      _id: "3c4d5e6f7g",
      date: "2024-09-18",
      title: "Manejo del Estrés en Mascotas",
      content: "Las mascotas pueden sufrir de estrés y ansiedad, afectando su bienestar general. Este artículo ofrece estrategias y técnicas para ayudar a los propietarios a gestionar el estrés en sus animales de compañía.<br>Desde la modificación del comportamiento hasta la medicación, exploraremos diversas soluciones.",
      image: "https://img.freepik.com/free-photo/side-view-upset-woman-home_23-2149598061.jpg?ga=GA1.1.1900990210.1730379746&semt=ais_hybrid"
   },
   {
      _id: "41d5e6f7g8h",
      date: "2024-09-10",
      title: "Nuevas Técnicas en Cirugía Veterinaria",
      content: "Un vistazo a las innovaciones en técnicas quirúrgicas veterinarias que están mejorando los resultados para los animales. Aprenderemos sobre la cirugía mínimamente invasiva y su aplicabilidad en diversas condiciones de salud.<br>Estos avances están revolucionando la práctica veterinaria.",
      image: "https://img.freepik.com/free-photo/cute-persian-cat-with-recovery-cone-after-surgery-veterinarian-woman-man-vet-putting-bandage-sick-fluffy-pet-animal-clinic_662251-2283.jpg?t=st=1730380001~exp=1730383601~hmac=a30691a5bae1875b9e6950bd8c4a0d4037dd47413839fe3b9013ce198e234294&w=996"
   },
   {
      _id: "5e6f71g8h9i",
      date: "2024-09-01",
      title: "Importancia de la Salud Mental en Veterinaria",
      content: "Discutiremos la salud mental en la profesión veterinaria, un aspecto crucial que a menudo se pasa por alto. Analizaremos las mejores prácticas para cuidar de la salud mental de los veterinarios y su impacto en la atención al paciente.<br>Es vital mantener un equilibrio para ofrecer la mejor atención posible.",
      image: "https://img.freepik.com/free-photo/close-up-veterinarian-taking-care-pet_23-2149143880.jpg?t=st=1730379768~exp=1730383368~hmac=b9d30ca4bcb84cbbd0e66facf081bd6e69ef6dc386c0f3a313c756c2ba12a7d3&w=996"
   },
   {
      _id: "1a2b31c4d5e",
      date: "2024-10-01",
      title: "Efectos del Cambio Climático en la Salud Animal",
      content: "Un estudio sobre cómo el cambio climático está afectando la salud de los animales y la veterinaria. Analizaremos las nuevas enfermedades que están surgiendo y cómo los veterinarios pueden prepararse para estos desafíos.",
      image: "https://img.freepik.com/free-photo/close-up-hands-touching-dog_23-2149271868.jpg?t=st=1730379897~exp=1730383497~hmac=cc5805e143f28def25a24aa81dba18c967bbff9b431827fe53530e9555d17ceb&w=996"
   },
   {
      _id: "2b31c4d5e6f",
      date: "2024-09-25",
      title: "Tendencias en Medicina Veterinaria",
      content: "Conoce las tendencias actuales en medicina veterinaria que están dando forma a la industria. Desde la telemedicina hasta la atención personalizada, exploraremos cómo estas innovaciones están transformando la práctica veterinaria.",
      image: "https://img.freepik.com/free-photo/close-up-veterinarian-taking-care-dog_23-2149100225.jpg?t=st=1730379756~exp=1730383356~hmac=2ef5e0d9c45bce0c49ac0b34ef9b323ee35b369feae907f9179feb90d3515f8e&w=996"
   },
   {
      _id: "3c4d5e6f71g",
      date: "2024-09-18",
      title: "La Importancia del Diagnóstico Precoz",
      content: "Un enfoque en la importancia del diagnóstico precoz en medicina veterinaria. Aprenderemos sobre las herramientas y técnicas que ayudan a detectar enfermedades antes de que se conviertan en problemas graves.",
      image: "https://img.freepik.com/premium-photo/veterinarian-doc-with-dog-cat-white-background-generative-ai_874904-126520.jpg?w=1060"
   },
   {
      _id: "4d5e36f7g8h",
      date: "2024-09-10",
      title: "Cuidado Integral para Animales de Compañía",
      content: "La atención integral es clave para la salud de los animales de compañía. Este artículo explora cómo integrar la atención médica y el bienestar general para mejorar la calidad de vida de nuestras mascotas.",
      image: "https://img.freepik.com/free-photo/close-up-doctor-clinic-with-smiley-dog_23-2148302267.jpg?t=st=1730380032~exp=1730383632~hmac=0d090a7c70ede8af79da7f6e6a407e9f65bdd22b64809aefc2338e98a782dc1a&w=900"
   },
   {
      _id: "5e6f7g58h9i",
      date: "2024-09-01",
      title: "Impacto de la Genética en la Salud Animal",
      content: "Analizaremos cómo la genética influye en la salud y comportamiento de las mascotas. Conoceremos cómo los veterinarios pueden utilizar esta información para proporcionar una atención más eficaz.",
      image: "https://img.freepik.com/free-photo/close-up-veterinarian-taking-care-pet_23-2149143892.jpg?t=st=1730379910~exp=1730383510~hmac=f623d51d008f8a0a2ee84efa694709db70ad80ba41623392dcd903fe16dda07f&w=996"
   }
];

export default function BlogPage() {
   const latestPost = FAKE_DATA.slice(0, 4);

   const restOfPosts = FAKE_DATA.slice(4);

   return (
      <MainLayout >
         <section className='BlogPage-main-container'>
            <h1 className='BlogPage--pageTitle'>Blog de Novedades</h1>

            <hr />

            <div className='BlogPage-wrapper-content'>
               <div className='BlogPage-lastPost-container'>
                  {latestPost.map((item, idx) => (
                     <BlogCard
                        idx={idx}
                        isLatest
                        item={item}
                        key={item._id}
                     />
                  ))}

               </div>
               <div className='BlogPage-generalPost-container'>
                  {restOfPosts && restOfPosts.map(item => (
                     <BlogCard
                        item={item}
                        key={item._id}
                     />
                  ))}
               </div>
            </div>

         </section>
      </MainLayout>
   )
}
