import './ValuesSection.css'
import IconCardText from '../../../components/IconCardText/IconCardText'
import IconFilled from '../../../components/IconFilled/IconFilled'
import { IconNetwork, IconHeartHandshake, IconFriends, IconPawFilled, IconBrandCodesandbox  } from '@tabler/icons-react';
const valuesToMap = [
   { icon: < IconNetwork className='aboutUsPage-values-icon' />, title: 'Excelencia', description: 'Buscamos la excelencia en todo lo que hacemos, desde la calidad de nuestras formaciones hasta el apoyo que brindamos a nuestra comunidad.' },
   { icon: < IconFriends className='aboutUsPage-values-icon' />, title: 'Colaboración', description: 'Creemos en el poder de trabajar juntos y aprender unos de otros para impulsar la profesión veterinaria.' },
   { icon: < IconHeartHandshake className='aboutUsPage-values-icon' />, title: 'Compromiso', description: 'Estamos comprometidos con el éxito y el crecimiento de nuestra comunidad.' },
   { icon: < IconPawFilled className='aboutUsPage-values-icon' />, title: 'Resiliencia', description: 'Creemos en la evolución constante para mantenernos a la vanguardia de la formación veterinaria.' },
   { icon: < IconBrandCodesandbox className='aboutUsPage-values-icon' />, title: 'Empatía', description: 'Nos permite comprender y compartir las necesidades de nuestra comunidad. Ver las cosas desde la perspectiva del otro.' },

]

export default function ValuesSection() {
   return (
      <div className='aboutUsPage-values-section'>
         <div className='aboutUsPage-values-section-heading'>
            <h2>VALORES</h2>
         </div>
         <div className='aboutUsPage-values-content-container'>
            {valuesToMap.map((item, idx) => (
               <IconCardText
                  key={idx} text={item.title}
                  description={item.description}
               >
                  <IconFilled
                     backgroundColor='var(--color-secondary)'
                     rounded='var(--border-radius-lg)'
                  >
                     {item.icon}
                  </IconFilled>
               </IconCardText>
            ))}
         </div>
      </div>
   )
}
