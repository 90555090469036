import React from 'react';
import "./PulseFABButton.css";
import {useNavigate} from "react-router-dom";

function PulseFabButton(props) {
    const navigate = useNavigate();
    return (
        <div className="pulseFabButton-container">
            <button
                className="pulseFabButton-button"
                onClick={() => {
                    navigate(`/catalogo`)
                }}
            >
                CATÁLOGO 2025
            </button>
        </div>
    );
}

export default PulseFabButton;